import { FC } from 'react';
import { IconProps } from '.';

export const IconShapeHeadEngineXS: FC<IconProps> = ({ width = '16px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.02178 6.64C2.20178 2.94 5.25178 0 9.00178 0C12.8718 0 16.0018 3.13 16.0018 7C16.0018 9.79 14.3618 12.2 12.0018 13.32V18H5.00178V15H4.00178C2.90178 15 2.00178 14.1 2.00178 13V10H0.501778C0.0917776 10 -0.148222 9.53 0.101778 9.2L2.02178 6.64ZM11.9818 7.39C11.9918 7.26 12.0018 7.13 12.0018 7C12.0018 6.86 11.9918 6.73 11.9618 6.61L12.8118 5.95C12.8918 5.89 12.9118 5.78 12.8618 5.69L12.0618 4.31C12.0218 4.22 11.9118 4.19 11.8218 4.22L10.8218 4.62C10.6118 4.46 10.3918 4.33 10.1518 4.23L10.0018 3.17C9.98178 3.07 9.90178 3 9.80178 3H8.20178C8.10178 3 8.02178 3.07 8.00178 3.17L7.85178 4.23C7.61178 4.33 7.38178 4.47 7.18178 4.62L6.18178 4.22C6.09178 4.19 5.99178 4.22 5.94178 4.31L5.14178 5.69C5.09178 5.79 5.11178 5.89 5.19178 5.95L6.04178 6.61C6.02178 6.73 6.00178 6.87 6.00178 7C6.00178 7.13 6.01178 7.26 6.02178 7.39L5.18178 8.05C5.10178 8.11 5.08178 8.22 5.13178 8.3L5.93178 9.69C5.98178 9.78 6.09178 9.81 6.18178 9.78L7.17178 9.38C7.38178 9.54 7.60178 9.67 7.85178 9.77L8.00178 10.83C8.02178 10.93 8.10178 11 8.20178 11H9.80178C9.90178 11 9.99178 10.93 10.0018 10.83L10.1618 9.77C10.4018 9.67 10.6218 9.54 10.8318 9.38L11.8218 9.78C11.9018 9.81 12.0118 9.78 12.0618 9.69L12.8618 8.3C12.9118 8.21 12.8918 8.11 12.8118 8.05L11.9818 7.39ZM7.57178 7.00001C7.57178 6.21001 8.21178 5.57001 9.00178 5.57001C9.79178 5.57001 10.4318 6.21001 10.4318 7.00001C10.4318 7.79001 9.79178 8.43001 9.00178 8.43001C8.21178 8.43001 7.57178 7.79001 7.57178 7.00001Z"
        fill="currentColor"
      />
    </svg>
  );
};
