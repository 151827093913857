import { ReactNode } from 'react';
import Select, { ClassNamesConfig, GroupBase, SingleValue, createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Spinner } from '..';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export type InputSelectOption = {
  label: string | JSX.Element;
  value: string;
  labelRaw?: string;
  isDisabled?: boolean;
};
interface InputSelectProps {
  id: string;
  options: InputSelectOption[];
  placeholder?: string;
  isLoading?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  selectedOption?: InputSelectOption;
  hideArrow?: boolean;
  noOptionsMessage?: string | ReactNode;
  tabIndex?: number;
  canCreate?: boolean;
  hideSelectedOptions?: boolean;
  handleSelectOption?: (option: SingleValue<InputSelectOption>) => void;
  handleInputChange?: (text: string) => void;
  handleFocus?: () => void;
  handleCreate?: (value: string) => void;
  autoFocus?: boolean;
  createLabel?: string;
}

export const InputSelect = ({
  id,
  options,
  placeholder,
  isLoading = false,
  isDisabled,
  isSearchable,
  isClearable,
  selectedOption,
  hideArrow,
  noOptionsMessage,
  tabIndex,
  canCreate,
  hideSelectedOptions = true,
  handleSelectOption,
  handleInputChange,
  handleFocus,
  handleCreate,
  createLabel = 'Create',
  autoFocus = false,
}: InputSelectProps) => {
  const customClassNames: ClassNamesConfig<
    InputSelectOption,
    false,
    GroupBase<InputSelectOption>
  > = {
    control: (state) =>
      `${
        state.isFocused ? '!border-primary-cta !shadow-none' : '!border-control'
      } !outline-none !body-2 !h-10 !rounded-md !bg-surface !text-on-background-main`,
    option: (state) =>
      `${state.isSelected ? '!bg-background' : '!bg-surface'} 
      ${state.isDisabled ? '!cursor-not-allowed' : ''} 
      !text-sm !text-on-surface hover:!bg-background !py-3`,
    singleValue: () => '!text-sm !text-on-background-main !m-0',
    input: () => '!text-on-background-main',
    valueContainer: () => '!pl-3.5 !pr-0 !text-on-background-main',
    menu: () => '!border-surface !shadow-lg !py-0 !rounded-[8px] !bg-surface !opacity-100 !z-[10]',
    menuList: () => '!p-0 !rounded-[10px]',
    indicatorSeparator: () => '!hidden',
    dropdownIndicator: () => `!text-on-background-main ${hideArrow ? '!hidden' : ''}`,
    placeholder: () => '!text-on-control-dimmed !text-sm !truncate',
    ...((!noOptionsMessage || typeof noOptionsMessage === 'string') && {
      noOptionsMessage: () => '!text-on-control-dimmed !text-sm !py-10 italic !bg-surface',
    }),
  };

  if (canCreate) {
    return (
      <CreatableSelect
        options={options}
        onCreateOption={handleCreate}
        value={selectedOption || null}
        onChange={handleSelectOption ? handleSelectOption : undefined}
        noOptionsMessage={() => noOptionsMessage || 'No options'}
        classNames={customClassNames}
        placeholder={placeholder}
        autoFocus={autoFocus}
        formatCreateLabel={(inputValue) => `${createLabel} "${inputValue}"`}
      />
    );
  }
  return (
    <Select
      id={id}
      classNamePrefix="select"
      hideSelectedOptions={hideSelectedOptions}
      value={selectedOption || null}
      isDisabled={isDisabled}
      noOptionsMessage={() => noOptionsMessage || 'No options'}
      isLoading={isLoading}
      loadingMessage={() => <Spinner wrapperClasses="py-8 flex items-center justify-center" />}
      isClearable={isClearable}
      isSearchable={isSearchable}
      onChange={handleSelectOption ? handleSelectOption : undefined}
      onInputChange={handleInputChange ? handleInputChange : undefined}
      onFocus={handleFocus ? handleFocus : undefined}
      // filter options by labelRaw (if defined) or label
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: (filterData: FilterOptionOption<InputSelectOption>) => {
          const { data } = filterData;
          return `${data.labelRaw ?? data.label}`;
        },
      })}
      placeholder={placeholder}
      name="color"
      options={options}
      {...(tabIndex && { tabIndex })}
      classNames={customClassNames}
    />
  );
};
