/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { DocumentNode, getOperationAST, print } from 'graphql';
import { Variables } from 'graphql-request';
import { Client, Sink } from 'graphql-ws';
import { useContext, useEffect } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import { ClientContext } from './client-provider';

export interface GraphqlFetcherArgs<T> {
  query: DocumentNode;
  variables?: T;
}

export function useClientSWR<T, V extends Variables>(
  key: GraphqlFetcherArgs<V> | null,
  options?: Partial<SWRConfiguration<T>>,
  service?: string,
) {
  const context = useContext(ClientContext);
  const client = service ? context.clients[service] : context.defaultClient;

  return {
    key,
    ...useSWR<T, any, GraphqlFetcherArgs<V>>(
      key,
      ({ query, variables }) => client.request<T>(query, variables),
      options,
    ),
  };
}

export function useClientSWRInfinite<T, V extends Variables>(
  getKey: (index: number) => GraphqlFetcherArgs<V>,
  options?: Partial<SWRInfiniteConfiguration<T>>,
  service?: string,
) {
  const context = useContext(ClientContext);
  const client = service ? context.clients[service] : context.defaultClient;

  return useSWRInfinite<T, any>(
    getKey,
    ({ query, variables }) => client.request<T>(query, variables),
    options,
  );
}

export function useClientSWRMutation<T, V extends Variables>(
  key: GraphqlFetcherArgs<any> | string,
  mutation: DocumentNode,
  options?: Partial<SWRMutationConfiguration<T, any, GraphqlFetcherArgs<any>, V>>,
  service?: string,
) {
  const context = useContext(ClientContext);
  const client = service ? context.clients[service] : context.defaultClient;

  return useSWRMutation<T, any, GraphqlFetcherArgs<any> | string, V>(
    key,
    (_key, { arg }) => client.request<T>(mutation, arg),
    options,
  );
}

export function useWebsocketSubscription<T, V extends Variables>(
  query: DocumentNode,
  sink: Sink<T>,
  variables?: V,
  service?: string,
): Client {
  const context = useContext(ClientContext);
  const websocketClient = service
    ? context.websocketClients[service]
    : context.defaultWebsocketClient;

  const operation = getOperationAST(query);
  const operationName = operation.name?.value;

  if (!operationName) throw new Error('Operation name is required');

  useEffect(() => {
    // dont subscribe if there are no variables
    if (variables === null) return;
    const dispose = websocketClient.subscribe<T>(
      { operationName, query: print(query), variables },
      {
        next: (result) => {
          if (result.data) {
            sink.next(result.data);
          } else if (result.errors) {
            sink.error(result.errors);
          }
        },
        error: (err) => {
          sink.error(err);
        },
        complete: () => {
          sink.complete();
        },
      },
    );

    const unsubscribe = () => {
      dispose();
    };

    return () => unsubscribe();
  }, [query, variables, websocketClient, operationName]);

  return websocketClient;
}
