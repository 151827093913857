import { IconCopy } from '@unique/icons';
import cn from 'classnames';
import 'katex/dist/katex.min.css';
import { Highlight, themes } from 'prism-react-renderer';
import { FC, memo, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  HeadingComponent,
  OrderedListComponent,
  SpecialComponents,
  TableDataCellComponent,
  TableHeaderCellComponent,
  TableRowComponent,
  UnorderedListComponent,
} from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { ButtonIcon, ButtonSize, ButtonVariant } from '..';
import { markdownSanitizeSchema } from '../helpers/markdownSanitizeSchema';
import {
  MarkdownHorizontalRuleType,
  MarkdownHTMLElementType,
  MarkdownLinkType,
  MarkdownParagraphType,
  MarkdownTableHeadType,
  MarkdownTableType,
} from '../types/markdown';
import { DefaultMarkdownLink } from './DefaultMarkdownLink';

const TABLE_ITEM_CLASSNAME = 'border border-[1px] border-white border-opacity-50';
const TABLE_CELL_CLASSNAME = 'px-2 py-2';

type MarkdownPreviewProps = {
  text: string;
  className?: string;
  handleSelectPrompt?: (prompt: string) => void;
  copyCode?: (text: string) => void;
  copiedCode?: string;
  codeLang?: string;
  customComponents?: Partial<NormalComponents & SpecialComponents> | undefined;
  wrapCode?: boolean;
};

// Using memo means <MarkdownPreview text={...} /> won't re-render if text doesn't change.
export const MarkdownPreview: FC<MarkdownPreviewProps> = memo(
  ({
    text,
    className = '',
    copyCode,
    copiedCode,
    codeLang,
    customComponents,
    wrapCode = false,
  }) => {
    const MarkdownHeading: HeadingComponent = ({ children }) => (
      <h1 className={`title-s mb-1.5 flex items-center gap-x-2 font-semibold ${className}`}>
        {children}
      </h1>
    );

    const MarkdownHeading2: HeadingComponent = ({ children }) => (
      <h2 className={`subtitle-1 mb-2 ${className}`}>{children}</h2>
    );

    const MarkdownCode = ({ children, inline }: { children: ReactNode; inline?: boolean }) => {
      if (inline) {
        return (
          <code
            className={cn({
              'body-2 bg-secondary text-on-secondary hover:bg-secondary-variant whitespace-normal rounded-lg px-1.5 py-1 transition':
                true,
              [className]: true,
            })}
          >
            {children}
          </code>
        );
      }

      const code = children?.toString() || '';

      return (
        <Highlight theme={themes.vsDark} code={code} language={codeLang || 'tsx'}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <div style={style} className={`${className} mb-4 rounded-lg p-4`}>
              <pre
                className={wrapCode ? 'whitespace-pre-wrap' : 'global-scrollbar overflow-x-auto'}
              >
                {tokens.map((line, i) => (
                  <div key={i} {...getLineProps({ line })} className={className}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </div>
                ))}
              </pre>
              {copyCode && (
                <ButtonIcon
                  icon={<IconCopy />}
                  className="!ml-auto !flex"
                  onClick={() => {
                    copyCode(code);
                  }}
                  variant={ButtonVariant.SECONDARY}
                  buttonSize={ButtonSize.SMALL}
                >
                  {`${copiedCode === code ? 'Copied' : 'Copy'}`}
                </ButtonIcon>
              )}
            </div>
          )}
        </Highlight>
      );
    };

    const MarkdownParagraph: MarkdownParagraphType = ({ children }) => (
      <p className={`mb-4 px-2 ${className}`}>{children}</p>
    );

    const MarkdownUnorderedList: UnorderedListComponent = ({ children }) => (
      <ul className={`mb-4 ml-6 list-disc px-2 ${className}`}>{children}</ul>
    );

    const MarkdownOrderedList: OrderedListComponent = ({ children }) => (
      <ol className={`mb-4 ml-6 list-decimal px-2 ${className}`}>{children}</ol>
    );

    const MarkdownSuperscript: MarkdownHTMLElementType = ({ children }) => (
      <sup
        className={`subtitle-2 bg-attention-variant text-on-attention-variant top-auto mr-1 inline-flex h-4 w-4 items-center justify-center rounded-md px-2 align-text-top last-of-type:mr-0 ${className}`}
      >
        {children}
      </sup>
    );

    const MarkdownTable: MarkdownTableType = ({ children }) => (
      <div className="overflow-x-auto px-2">
        <table className={`markdown-table my-5 w-full border-collapse ${className}`}>
          {children}
        </table>
      </div>
    );

    const MarkdownTableHead: MarkdownTableHeadType = ({ children }) => (
      <thead className={cn(TABLE_ITEM_CLASSNAME, className)}>{children}</thead>
    );

    const MarkdownTableHeadCell: TableHeaderCellComponent | undefined = ({ children }) => (
      <th className={cn(TABLE_ITEM_CLASSNAME, TABLE_CELL_CLASSNAME, 'text-left', className)}>
        {children}
      </th>
    );

    const MarkdownTableRow: TableRowComponent = ({ children }) => (
      <tr className={cn(TABLE_ITEM_CLASSNAME, className)}>{children}</tr>
    );

    const MarkdownTableCell: TableDataCellComponent = ({ children }) => (
      <td className={cn(TABLE_ITEM_CLASSNAME, TABLE_CELL_CLASSNAME, className)}>{children}</td>
    );

    const MarkdownLink: MarkdownLinkType = ({ children, href, target }) => {
      return (
        <DefaultMarkdownLink href={href} target={target}>
          {children}
        </DefaultMarkdownLink>
      );
    };

    const MarkdownHorizontalRule: MarkdownHorizontalRuleType = () => (
      <hr className={`my-5 ${className}`} />
    );

    const HtmlHighlight: MarkdownHTMLElementType = ({ children }) => (
      <mark
        className={`body-1 bg-info text-on-info rounded-md p-1 px-2 font-semibold ${className}`}
      >
        {children}
      </mark>
    );

    return (
      <ReactMarkdown
        className="break-words"
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, [rehypeSanitize, markdownSanitizeSchema]]}
        components={{
          h1: MarkdownHeading,
          h2: MarkdownHeading2,
          p: MarkdownParagraph,
          ul: MarkdownUnorderedList,
          ol: MarkdownOrderedList,
          sup: MarkdownSuperscript,
          table: MarkdownTable,
          thead: MarkdownTableHead,
          th: MarkdownTableHeadCell,
          tr: MarkdownTableRow,
          td: MarkdownTableCell,
          a: MarkdownLink,
          hr: MarkdownHorizontalRule,
          mark: HtmlHighlight,
          code({ inline, children }) {
            return <MarkdownCode inline={inline}>{children}</MarkdownCode>;
          },
          ...customComponents,
        }}
      >
        {text}
      </ReactMarkdown>
    );
  },
);
MarkdownPreview.displayName = 'MarkdownPreview';
